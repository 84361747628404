import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { getRichText } from 'src/helpers'
import { Container } from './NoPositions.styled'

const render = props => queryData => (
  <Container
    dangerouslySetInnerHTML={{
      __html: getRichText(
        'prismicEmploymentPage.data.no_positions_text',
        queryData
      ),
    }}
    {...props}
  />
)

export const NoPositions = props => (
  <StaticQuery
    query={graphql`
      {
        prismicEmploymentPage {
          id
          data {
            no_positions_text {
              text
              html
            }
          }
        }
      }
    `}
    render={render(props)}
  />
)
