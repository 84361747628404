import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Component from '@reach/component-component'
import { get, map, filter, matchesProperty, sortBy, indexOf } from 'lodash'

import { nodes } from 'src/helpers'
import { ChalkboardBox } from 'src/components/Shared/ChalkboardBox/ChalkboardBox'
import { LargeContentContainer } from 'src/components/Layout/Structure'
import { MediumContentContainer } from 'src/components/Layout/Structure'
import { Layout } from 'src/components/Layout/Layout'
import { Positions } from 'src/components/Employment/Positions/Positions'
import { Content, Locations, Location } from './_employment.styled'

const EmploymentPage = ({ data }) => {
  const page = get(data, 'prismicEmploymentPage')
  const locationsPreferredOrder = [
    'Kailua Beach',
    'Kailua Town',
    'Kapolei',
    'Waimanalo',
  ]
  const locations = sortBy(nodes(get(data, 'allPrismicLocation')), x =>
    indexOf(locationsPreferredOrder, get(x, 'data.location_name'))
  )
  const positions = nodes(get(data, 'allPrismicEmploymentPosition'))

  return (
    <Layout activePageName="employment">
      <Helmet title={get(page, 'data.title.text')} />
      <ChalkboardBox>
        <div
          dangerouslySetInnerHTML={{
            __html: get(data, 'prismicEmploymentPage.data.intro.html'),
          }}
        />
      </ChalkboardBox>
      <Content>
        <LargeContentContainer>
          <Component
            initialState={{ activeLocation: get(locations, '[0].uid') }}
          >
            {({ setState, state }) => (
              <>
                <Locations>
                  {map(locations, location => (
                    <Location
                      key={get(location, 'data.location_name')}
                      onClick={() =>
                        setState({
                          activeLocation: get(location, 'uid'),
                        })
                      }
                      isActive={state.activeLocation === get(location, 'uid')}
                    >
                      {get(location, 'data.location_name')}
                    </Location>
                  ))}
                </Locations>
                <MediumContentContainer>
                  <Positions
                    positions={filter(
                      positions,
                      matchesProperty(
                        'data.location.document.uid',
                        state.activeLocation
                      )
                    )}
                  />
                </MediumContentContainer>
              </>
            )}
          </Component>
        </LargeContentContainer>
      </Content>
    </Layout>
  )
}

export default EmploymentPage

export const query = graphql`
  {
    prismicEmploymentPage {
      id
      data {
        title {
          text
        }
        intro {
          html
        }
      }
    }
    allPrismicLocation {
      edges {
        node {
          uid
          data {
            location_name
          }
        }
      }
    }
    allPrismicEmploymentPosition(
      sort: { fields: [last_publication_date], order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            display_title {
              text
            }
            location {
              document {
                ... on PrismicLocation {
                  uid
                  data {
                    location_name
                  }
                }
              }
            }
            type
            short_description {
              html
            }
          }
        }
      }
    }
  }
`
