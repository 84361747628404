import styled from 'react-emotion'
import t from 'src/theme'

export const Container = styled.div`
  color: ${t.c.darkGray};
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-0.5)};
  line-height: ${t.lh.wide};
  text-align: center;

  h1 {
    color: ${t.c.lightGreen};
    font-family: ${t.ff.sansHeadline};
    font-size: ${t.f(1)};
    font-weight: bold;
    letter-spacing: 0.05em;
    margin: 0 0 ${t.s(0)};
    text-transform: uppercase;
  }
`
