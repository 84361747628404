import styled from 'react-emotion'
import t from 'src/theme'

export const Content = styled.div`
  background-color: ${t.c.lightCream};
  color: ${t.c.darkGray};
  padding: ${t.s(2)};

  ${t.mq.m} {
    padding: ${t.s(5, 2)};
  }
`

export const Locations = styled.ul`
  border: 2px solid ${t.c.gray};
  border-radius: ${t.r(-7)};
  color: ${t.c.gray};
  display: flex;
  flex-direction: column;
  font-family: ${t.ff.monoBody};
  margin: 0 0 ${t.s(2)};
  padding: ${t.s(-3)};
  position: relative;

  ${t.mq.m} {
    flex-direction: row;
    margin-bottom: ${t.s(4)};
  }
`

export const Location = styled.li`
  align-items: center;
  background-color: ${p => (p.isActive ? t.c.gray : t.c.white)};
  border: 2px solid ${t.c.gray};
  border-top-width: 0;
  color: ${p => (p.isActive ? t.c.white : 'inherit')};
  cursor: pointer;
  display: flex;
  flex: 1 1 0px;
  font-size: ${t.f(1)};
  font-weight: 500;
  justify-content: center;
  line-height: ${t.lh.copy};
  list-style: none;
  margin: 0;
  padding: ${t.s(2, 3)};
  text-align: center;
  text-transform: uppercase;
  transition-duration: ${t.t};
  transition-property: background-color, color;

  ${t.mq.m} {
    border-left-width: 0;
    border-top-width: 2px;
  }

  &:first-child {
    border-top-width: 2px;
    border-top-left-radius: ${t.r(-8)};
    border-top-right-radius: ${t.r(-8)};

    ${t.mq.m} {
      border-left-width: 2px;
      border-top-right-radius: 0;
      border-bottom-left-radius: ${t.r(-8)};
    }
  }

  &:last-child {
    border-bottom-left-radius: ${t.r(-8)};
    border-bottom-right-radius: ${t.r(-8)};

    ${t.mq.m} {
      border-top-right-radius: ${t.r(-8)};
      border-bottom-left-radius: 0;
    }
  }

  &:hover {
    background-color: ${t.c.gray};
    color: ${t.c.white};
  }
`
