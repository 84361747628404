import React from 'react'

import {
  Container,
  Content,
  Title,
  Details,
  Description,
  StyledLink,
} from './Position.styled'

export const Position = ({
  to,
  title,
  type,
  locationName,
  children,
  ...props
}) => (
  <Container>
    <Title>{title}</Title>
    <Content>
      <Details>
        {locationName} / {type}
      </Details>
      {children && <Description>{children}</Description>}
      {to && <StyledLink to={to}>Read More</StyledLink>}
    </Content>
  </Container>
)
