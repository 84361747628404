import React from 'react'
import { map, get, isEmpty } from 'lodash'

import { Position } from 'src/components/Employment/Position/Position'
import { NoPositions } from 'src/components/Employment/NoPositions/NoPositions'
import { Container } from './Positions.styled'

export const Positions = ({ positions = [], ...props }) => (
  <Container {...props}>
    {isEmpty(positions) ? (
      <NoPositions />
    ) : (
      map(positions, position => (
        <Position
          key={get(position, 'data.display_title.text')}
          title={get(position, 'data.display_title.text')}
          to={`/employment/${get(position, 'uid')}/`}
          type={get(position, 'data.type')}
          locationName={get(
            position,
            'data.location.document.data.location_name'
          )}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: get(position, 'data.short_description.html'),
            }}
          />
        </Position>
      ))
    )}
  </Container>
)
