import styled from 'react-emotion'

import t from 'src/theme'
import { Link } from 'src/components/Shared/Link/Link'

export const Container = styled.div`
  border-bottom: 1px solid ${t.c.gray};
  display: flex;
  flex-direction: column;
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-0.5)};
  margin-bottom: ${t.s(2)};
  padding-bottom: ${t.s(2)};
  text-align: center;

  ${t.mq.m} {
    flex-direction: row;
    text-align: left;
  }

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`

export const Title = styled.h3`
  color: ${t.c.lightGreen};
  font-family: ${t.ff.sansHeadline};
  font-size: ${t.f(1)};
  font-weight: bold;
  letter-spacing: 0.05em;
  margin: 0 0 ${t.s(0)};
  text-transform: uppercase;

  ${t.mq.m} {
    flex-basis: 25%;
    margin-right: ${t.s(3)};
  }
`

export const Content = styled.div`
  ${t.mq.m} {
    flex-basis: 75%;
  }
`

export const Details = styled.p`
  color: ${t.c.gray};
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: ${t.s(0)};
  text-transform: uppercase;
`

export const Description = styled.div`
  line-height: ${t.lh.wide};
  margin-bottom: ${t.s(0)};
`

export const StyledLink = styled(Link)`
  color: ${t.c.lightGreen};
  font-weight: bold;
  letter-spacing: 0.1em;
  text-decoration: none;
  text-transform: uppercase;
`
